import React, { useState } from 'react';


const RankTextCriterial = ({ description, setDescription, keywords, setKeywords }) => {

    const [newKeyword, setNewKeyword] = useState("");

    const handleDescriptionChange = (e) => {
      setDescription(e.target.value);
    };
  
    const handleNewKeywordChange = (e) => {
      setNewKeyword(e.target.value);
    };


    const handleAddKeyword = () => {
        if (newKeyword && !keywords.includes(newKeyword)) {
          setKeywords([...keywords, newKeyword]);
          setNewKeyword("");
        }
      };

    const handleRemoveKeyword = (keyword) => {
      setKeywords(keywords.filter((item) => item !== keyword));
    };

    return (
        <div className="rank-text-criterial">
          <div className="rank-text-description">
            <h3>Descripción:</h3>
            <textarea
              value={description}
              onChange={handleDescriptionChange}
              rows={4}
              style={{ width: "100%", resize: "vertical" }}
            />
          </div>

          <div className="rank-text-keywords">
            <h3>Palabras Claves</h3>
            <div className="input-container">
              <input
                type="text"
                value={newKeyword}
                onChange={handleNewKeywordChange}
                placeholder="Agregar palabra clave"
              />
              <button onClick={handleAddKeyword}>Agregar</button>
            </div>
            <div className="keywords-list">
              {keywords.map((keyword, index) => (
                <div className="keyword-item" key={index}>
                  {keyword} <button onClick={() => handleRemoveKeyword(keyword)}>x</button>
                </div>
              ))}
            </div>
          </div>
        </div>
      );
    };
  
  export default RankTextCriterial;