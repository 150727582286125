import React, { useState, useEffect } from 'react';
import './App.css';
import ImageViewer from './components/ImageViewer';
import ImageList from './components/ImageList';
import RankTypeSelector from './components/RankTypeSelector';
import RankTextCriterial from './components/RankTextCriterial';
import PaginationControls from './components/PaginationControls';
import {getImages, getRankTypes} from './services/imageService';
import {postRankImage, postImageCollection, postImageInfo, postMergeRankImage} from './services/imageService';

const App = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [images, setImages] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [totalImages, setTotalImages] = useState(0);
  const [pageSorted, setPageSorted] = useState(0);
  const [pageSizeSorted, setPageSizeSorted] = useState(20);
  const [totalImagesSorted, setTotalImagesSorted] = useState(0);
  const [userImage, setUserImage] = useState(null);
  const [userImageDescription, setUserImageDescription] = useState(null);
  const [userImageKeywords, setUserImageKeywords] = useState(null);
  const [rankTypes, setRankTypes] = useState(null);
  const [rankNature, setRankNature] = useState(null);
  const [rankImages, setRankImages] = useState(null);
  const [rankImageIds, setRankImageIds] = useState(null);
  const [selectedRankType, setSelectedRankType] = useState(null);
  const [selectedExtraRankType, setSelectedExtraRankType] = useState(null);
  const [selectedRankDescription, setSelectedRankDescription] = useState(null);
  const [selectedExtraRankDescription, setSelectedExtraRankDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);

  const styles = {
    button: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 10px',
      fontSize: '12px',
      backgroundColor: 'transparent',
      color: 'black',
      fontWeight: 'bold',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    icon: {
      marginRight: '8px',
      width: '20px',
      height: '20px',
    },
  };

  const loadImages = async () => {
    const result = await getImages(page, pageSize);
    setImages(result.images);
    setTotalImages(result.total);
    const ranks = await getRankTypes()
    setRankTypes(ranks);
    
    if (rankImages){
      setIsLoading(true);
      try{
        const data = await postImageCollection(rankImageIds.image_id_collection, pageSorted, pageSizeSorted);
        setRankImages(data.images)
      } catch (error) {
        setRankImages(null)
        console.error('Error durante la operación:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadImages();
  }, [page, pageSize, pageSorted, pageSizeSorted]);

  const handleNextPage = () => {
    if (page * pageSize < totalImages) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleNextPageSorted = () => {
    if (pageSorted * pageSizeSorted < totalImagesSorted) {
      setPageSorted((prevPageSorted) => prevPageSorted + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handlePreviousPageSorted = () => {
    if (pageSorted > 1) {
      setPageSorted((prevPageSorted) => prevPageSorted - 1);
    }
  };

  const handlePageSizeChange = (event) => {
    setPageSize(Number(event.target.value));
    setPage(1);
  };

  const handlePageSizeChangeSorted = (event) => {
    setPageSizeSorted(Number(event.target.value));
    setPageSorted(1);
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const imageUrl = e.target.result;
        setUserImage(imageUrl);
        setUserImageDescription("");
        setUserImageKeywords([]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleRankTypeChange = (newRankType, newRankDescription, newRankNature) => {
    setRankNature(newRankNature)
    setSelectedRankType(newRankType);
    setSelectedRankDescription(newRankDescription);
    setRankImages(null);
    setRankImageIds(null);
    setTotalImagesSorted(0)
  };

  const handleUpdateImageDescription = (description) => {
    setUserImageDescription(description)
  }

  const handleUpdateImageKeywords = (keywords) => {
    setUserImageKeywords(keywords)
  }

  const handleGenerateImageInfo = async () => {
    setIsGenerating(true)
    const imageInfo = await postImageInfo(userImage.split(',')[1]);
    setUserImageDescription(imageInfo.description);
    setUserImageKeywords(imageInfo.keywords);
    setIsGenerating(false)
  }

  const handleImagesRank = async () => {
    setIsLoading(true);
    try{
      let rankIds = []
      if (selectedExtraRankType){
        rankIds = await postMergeRankImage(userImage.split(',')[1], [selectedRankType, selectedExtraRankType],null,null,userImageDescription, userImageKeywords);
      }
      else{
        rankIds = await postRankImage(userImage.split(',')[1], selectedRankType,null,null,userImageDescription, userImageKeywords);
      }
      
      setRankImageIds(rankIds);
      const rankImages = await postImageCollection(rankIds.image_id_collection, pageSorted, pageSizeSorted);
      setTotalImagesSorted(rankImages.total);
      setRankImages(rankImages.images)
    } catch (error) {
      setRankImages(null)
      console.error('Error durante la operación:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMergeButton = () => {
    const icon = document.getElementById("mergeIcon");
    const text = document.getElementById("buttonText");
    if (!isMerging) {
      icon.src = "/cancel.png";
      text.innerHTML = "No combinar";
    } else {
        icon.src = "/merge.png";
        text.innerHTML = "Combinar";
        setSelectedExtraRankType(null);
        setSelectedExtraRankDescription(null);
    }
    setIsMerging(!isMerging)
  }

  const handleExtraRankTypeChange = (newRankType, newRankDescription, newRankNature) => {
    if (rankNature !== 'TEXT'){
      setRankNature(newRankNature)
    }
    setSelectedExtraRankType(newRankType);
    setSelectedExtraRankDescription(newRankDescription);
    setRankImages(null);
    setRankImageIds(null);
    setTotalImagesSorted(0)
  }

  return (
    <div className="App">
      <h1>Visor de Imágenes</h1>
      <div className="main-container">
        <div className="image-list-container">
          <PaginationControls
            page={page}
            pageSize={pageSize}
            totalImages={totalImages}
            handlePreviousPage={handlePreviousPage}
            handleNextPage={handleNextPage}
            handlePageSizeChange={handlePageSizeChange}
          />
          <div className="image-panel">
            <ImageList images={images} onImageSelect={setSelectedImage} />
          </div>
        </div>

        <div className="image-viewer-container">
          <div className="upload-section">
            <label htmlFor="upload" className="upload-label">
              Subir una imagen:
            </label>
            <input className="hidden-input" type="file" id="upload" onChange={handleImageUpload} />
          </div>
          <ImageViewer selectedImage={selectedImage} userImage={userImage} />
          {userImage && (
            <div className="rank-panel">
              <RankTypeSelector rankTypes={rankTypes} onRankTypeChange={handleRankTypeChange}/>
              {isLoading && <div className="loading-spinner">🔎</div>}
              {!isLoading && <button disabled={!selectedRankType} onClick={handleImagesRank}>Ordenar</button>}
              <button className="boton-imagen-texto" style={styles.button} onClick={handleMergeButton}>
                <img id="mergeIcon" src="/merge.png" style={styles.icon}/>
                <span id="buttonText">Combinar</span>
              </button>
              {rankNature === 'TEXT' && (
              <button className={isGenerating? "loading-spinner": "button"} style={styles.button} onClick={handleGenerateImageInfo}>
                <img src="/google-gemini-icon.svg" style={styles.icon} alt="Generar"/>
                <span>Interpretar</span>
              </button>)}
            </div>)
          }
          {isMerging && (<div className="extra-algorith-rank">
            <RankTypeSelector rankTypes={rankTypes} onRankTypeChange={handleExtraRankTypeChange}/>
          </div>
          )}
          { userImage && rankNature === 'TEXT' && (
            <RankTextCriterial
              description={userImageDescription}
              setDescription={handleUpdateImageDescription}
              keywords={userImageKeywords}
              setKeywords={handleUpdateImageKeywords}
            />
           )
          }
          
        </div>

        <div className="image-panel-results">
          <label>Colección {`Ordenada ${selectedRankDescription? selectedRankDescription:''}${selectedExtraRankDescription? ' y '+selectedExtraRankDescription:''}`}</label>
          {totalImagesSorted !== 0 && (<PaginationControls
            page={pageSorted}
            pageSize={pageSizeSorted}
            totalImages={totalImagesSorted}
            handlePreviousPage={handlePreviousPageSorted}
            handleNextPage={handleNextPageSorted}
            handlePageSizeChange={handlePageSizeChangeSorted}
          />)}
         {rankImages && ( <div className="image-panel">
            <ImageList images={rankImages} onImageSelect={setSelectedImage} />
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default App;
