import React, { useState } from 'react';

const RankTypeSelector = ({ rankTypes, onRankTypeChange }) => {
  const [selectedRankType, setSelectedRankType] = useState("");

  const handleChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const selectedValue = e.target.value;
    setSelectedRankType(selectedValue);
    onRankTypeChange(selectedValue, selectedOption.text, selectedOption.getAttribute("nature"));
  };

  return (
    <div className="rank-type-selector">
      <label htmlFor="rankType" className="rank-type-label">
        Algoritmo de búsqueda:
      </label>
      <select
        id="rankType"
        value={selectedRankType}
        onChange={handleChange}
        className="rank-type-select"
      >
        <option value="" disabled>
          Seleccione un Criterio
        </option>
        
        {rankTypes.map((type) => (
          <option key={type.rank_type} value={type.rank_type} nature={type.nature}>
            {type.description}
          </option>
        ))}
      </select>
    </div>
  );
};

export default RankTypeSelector;